import moment from 'moment'

export const toCurrency = value => {
  return value.toFixed(2)
}

export const dateUtils = {
  getTime(d) {
    return moment(d).format('hh:mm A')
  }
}

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getVariantName = (variant, hasDiscountAmount) => {
  if(hasDiscountAmount) {
    return `${variant.name} ($${toCurrency(variant.mainPrice)})`  
  }
  return `${variant.name} ($${toCurrency(variant.price)})`
}
