import React from 'react'
import PropTypes from 'prop-types'

import Loader from '../../Loader'
import './styles.scss'

export const types = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
}

const Button = props => {
  const {
    type = types.PRIMARY,
    text = '',
    className = '',
    loading = false,
    disabled = false,
    testId = ''
  } = props

  const handleClick = e => {
    if (props.onClick) {
      props.onClick(e)
    }
  }

  const isDisabled = disabled || loading

  return (
    <button
      data-testid={ testId }
      className={ `eg-button ${type} ${className} ${isDisabled ? 'disabled' : ''}` }
      onClick={ handleClick }
      disabled={ isDisabled }
    >
      {
        loading ? (
          <Loader />
        ) : (
          <span className='label'>{ text }</span>
        )
      }
    </button>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(Object.values(types)),
  className: PropTypes.string,
  loading: PropTypes.bool,
  testId: PropTypes.string,
  onClick: PropTypes.func
}

export default Button
