import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Picker from '../Picker'
import { Button } from '../FormComponents'
import './styles.scss'

const StoreSelector = props => {
  const { stores, selectedStore, opened = false, onChange, onCancel } = props
  const [ isClicked, setIsClicked ] = useState(false)
  const [ selectedOption, setSelectedOption ] = useState(selectedStore)

  const hidePicker = () => {
    setIsClicked(false)
    const body = document.body
    body.style.position = ''
    body.style.top = ''
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
    window.scrollTo(0, parseInt(scrollY || '0') * -1);

  }

  const handleChangeClick = () => {
    setIsClicked(true)
    setSelectedOption(selectedStore)
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
    const body = document.body
    body.style.position = 'fixed'
    body.style.top = `-${scrollY}`
  }

  const handleConfirmClick = () => {
    if (onChange) {
      onChange(selectedOption)
    }
    hidePicker()
  }

  const handlePickerChange = item => {
    setSelectedOption(item)
  }

  const handleBackgroundClick = () => {
    setSelectedOption(selectedStore)
    hidePicker()
    if (onCancel) {
      onCancel()
    }
  }

  const items = stores.map((s, i) => {
    return {
      item: s,
      content: () => (
        <div className='item' key={ `store-selector-item-${i}` }>
          <div className='name'>{ s.name }</div>
          <div className='address'>{ s.address }</div>
        </div>
      )
    }
  })

  return (
    <div className='zsf-store-selector'>
      <div>
        <div className='name'>{ selectedStore.name }</div>
        <div className='address'>{ selectedStore.address }</div>
      </div>
      <div data-testid='store-selector-change' className='store-change' onClick={ handleChangeClick }>CHANGE</div>
      {
        (isClicked || opened) && (
          <>
            <div data-testid='store-selector-bg' className='background' onClick={ handleBackgroundClick } />
            <div className='picker-wrapper'>
              <div className='title'>Pickup location</div>
              <Picker items={ items } selectedItem={ selectedOption } onChange={ handlePickerChange } />
              <Button
                testId='store-selector-confirm'
                className='button'
                text='CONFIRM'
                onClick={ handleConfirmClick }
              />
            </div>
          </>
        )
      }
    </div>
  )
}

StoreSelector.propTypes = {
  stores: PropTypes.array.isRequired,
  selectedStore: PropTypes.object.isRequired,
  opened: PropTypes.bool,
  onChange: PropTypes.func
}

export default StoreSelector
