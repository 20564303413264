import { apiCall } from './http'

export const createPayment = async (priceId, qty, store) => {
  const STRIPE_BASE_URL = `${process.env.GATSBY_STRIPE_BASE_URL}/stripe/payment`
  return apiCall(STRIPE_BASE_URL, {
    method: 'POST',
    data: {
      price: priceId,
      quantity: qty,
      store_uuid: store.uuid
    }
  })
}

export const updatePaymentMetadata = async (clientSecret, notes, store, priceId, qty, paymentId) => {
  const STRIPE_BASE_URL = `${process.env.GATSBY_STRIPE_BASE_URL}/stripe/payment/${paymentId}`
  const payload = {
    store_uuid: store.uuid,
    price: priceId,
    quantity: qty
  }
  if (notes) {
    payload.notes = notes
  }
  const res = await apiCall(STRIPE_BASE_URL, {
    method: 'PATCH',
    data: payload
  })
  return {
    ...res,
    clientSecret,
    paymentId
  }
}
