import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { EGAnalytics, analyticEvents } from '../../services/analytics'
import Header from '../Header'
import OrderCard from '../OrderCard'
import './styles.scss'

const CheckoutSuccess = props => {
  const { paymentData, variant, quantity, productData, store } = props
  const orderDetails = {
    code: paymentData.code,
    description: `${productData.name} - ${variant.name}`,
    unit_price: variant.price,
    quantity,
    tax: paymentData.tax,
    subtotal: paymentData.subtotal,
    total: paymentData.total
  }

  useEffect(() => {
    EGAnalytics.track(analyticEvents.ORDER_CONFIRMATION_PAGE_VIEWED, {
      segmentPageName_: 'Order Success Page Viewed',
      itemName: orderDetails.description,
      itemUnitPrice: orderDetails.unit_price,
      itemQty: orderDetails.quantity
    })

   // Tracking only one time when user completes the checkout
    EGAnalytics.track(analyticEvents.CHECKOUT_COMPLETED, {
      itemName: orderDetails.description,
      itemUnitPrice: orderDetails.unit_price,
      itemQty: orderDetails.quantity,
      orderSubtotal: orderDetails.subtotal,
      orderSalesTax: orderDetails.tax,
      orderTotal: orderDetails.total
    })
  }, [
    orderDetails.description,
    orderDetails.quantity,
    orderDetails.subtotal,
    orderDetails.total,
    orderDetails.tax,
    orderDetails.unit_price
  ])

  return (
    <div className='zsf-checkout-success'>
      <Helmet title={ `${store.name} | Order Confirmation` } />
      <Header title={ store.name } />
      <div className='body'>
        <div className='icon-ok' />
        <div className='success-msg'>
          Thank you!<br />
          Your order has been placed.
        </div>
        <div className='note'>
          {/* TODO: keep in mind to change the phone in case we do this for other stores */}
          For additional request or other immediate needs,
          give us a call at <a href='tel:6505396310' className='phone'>(650) 539-6310</a>.
        </div>
        <OrderCard order={ orderDetails } store={ store } />
      </div>
    </div>
  )
}

CheckoutSuccess.propTypes = {
  paymentData: PropTypes.object,
  variant: PropTypes.object,
  quantity: PropTypes.number,
  description: PropTypes.string
}

export default CheckoutSuccess
