import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  PaymentRequestButtonElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { useForm } from 'react-hook-form'

import { updatePaymentMetadata } from '../../../services/checkout'
import Pickup from '../../Pickup'
import { toCurrency } from '../../../utils'
import { Button } from '../../FormComponents'
import SectionDivision from '../../SectionDivision'
import Message from '../../Message'
import StoreSelector from '../../StoreSelector'
import { stores } from '../../../data'
import './styles.scss'

export const handlePaymentRequestComplete = async (opts) => {
  const {
    stripeClient: stripe,
    paymentData,
    onPrEvent: ev,
    successCallback
  } = opts

  try {
    const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
      paymentData.clientSecret,
      { payment_method: ev.paymentMethod.id },
      { handleActions: false }
    )

    if (confirmError) {
      ev.complete('fail')
      throw new Error('Something went wrong. Please try with another payment method')
    } else {
      ev.complete('success')
      // Check if the PaymentIntent requires any actions and if so let Stripe.js handle the flow.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await stripe.confirmCardPayment(paymentData.clientSecret)
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
          // setError('Something went wrong. Please try with another payment method')
          throw new Error('Something went wrong. Please try with another payment method')
        } else {
          successCallback()
        }
      } else {
        successCallback()
      }
    }
  } catch (e) {
    ev.complete('fail')
    throw new Error('Something went wrong. Please try with another payment method')
  }
}

const cardStyle = {
  showIcon: true,
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#a4aeb6'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
}

const registerOptions = {
  name: {
    required: 'Name is required'
  },
  zipcode: {
    required: 'Zipcode is required',
    pattern: {
      value: /^\d{5}$|^\d{5}-\d{4}$/,
      message: 'Invalid zipcode'
    }
  },
  phone: {
    required: 'Phone number is required',
    pattern: {
      value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
      message: 'Invalid phone number'
    }
  },
  email: {
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Invalid email address'
    }
  }
}

const CheckoutForm = props => {
  const stripe = useStripe()
  const [ cardError, setCardError ] = useState()
  const [ paymentRequest, setPaymentRequest ] = useState(null)
  const [ cardValidationState, setCardValidationState ] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false
  })
  const [ submitting, setSubmitting ] = useState(false)
  const [ error, setError ] = useState(null)
  const elements = useElements()
  const { register, handleSubmit, formState, errors, getValues } = useForm({
    mode: 'all',
    defaultValues: {
      notes: '',
      name: '',
      email: '',
      zipcode: ''
    }
  })
  const [ paymentSent, setPaymentSent ] = useState(false)
  const { isValid } = formState
  const {
    paymentData,
    store,
    variant,
    selectedQuantity,
    onStoreChange,
    onPaymentComplete,
    onStoreLoading,
    onStoreLoadingComplete
  } = props

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: process.env.GATSBY_BRAND_TITLE,
          amount: parseInt(paymentData.total * 100),
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true
      })

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr)
        }
      })
    }
  }, [stripe, paymentData])

  useEffect(() => {
    if (stripe && paymentRequest && !paymentSent) {
      setPaymentSent(true)
      paymentRequest.on('paymentmethod', async (onPaymentCallbackEvent) => {
        try {
          await handlePaymentRequestComplete({
            stripeClient: stripe,
            paymentData,
            onPrEvent: onPaymentCallbackEvent,
            successCallback: onPaymentComplete
          })
        } catch (e) {
          setError(e.message)
        }
      })
    }
  }, [
    paymentRequest,
    paymentData,
    stripe,
    onPaymentComplete,
    paymentSent
  ])

  // when user clicks Google Pay / Apple Pay button
  // we update the metadata user changed
  const handlePaymentRequestClick = async () => {
    const formData = getValues()
    await updatePaymentMetadata(
      paymentData.clientSecret,
      formData.notes,
      store,
      variant.priceId,
      selectedQuantity,
      paymentData.paymentId
    )
  }

  const handleCardChange = (event) => {
    setCardError(event.error)
    setCardValidationState({
      ...cardValidationState,
      [event.elementType]: event.complete
    })
  }

  const formIsValid = () => {
    const { cardNumber, cardExpiry, cardCvc } = cardValidationState
    return (cardNumber && cardExpiry && cardCvc && isValid)
  }

  const handleErrorClose = () => {
    setError(null)
  }

  const onSubmit = async data => {
    if (formIsValid()) {
      setSubmitting(true)
      const paymentMethod = {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: data.name,
          phone: data.phone,
          address: {
            postal_code: data.zipcode
          }
        }
      }
      if (data.email) {
        paymentMethod.billing_details.email = data.email
      }

      try {
        await updatePaymentMetadata(
          paymentData.clientSecret,
          data.notes,
          store,
          variant.priceId,
          selectedQuantity,
          paymentData.paymentId
        )
        const payload = await stripe.confirmCardPayment(paymentData.clientSecret, {
          payment_method: paymentMethod
        })
        if (payload.error) {
          setError(payload.error.message)
          setSubmitting(false)
        }

        if (!payload.error && props.onPaymentComplete) {
          props.onPaymentComplete()
        }
      } catch(e) {
        setError(e.message)
        setSubmitting(false)
      }

    }
  }

  const handleStoreChange = async (store) => {
    const formData = getValues()
    try {
      if (onStoreLoading) onStoreLoading()
      const updatedTotals = await updatePaymentMetadata(
        paymentData.clientSecret,
        formData.notes,
        store,
        variant.priceId,
        selectedQuantity,
        paymentData.paymentId
      )

      if (onStoreChange) {
        onStoreChange(store, updatedTotals)
      }
      if (onStoreLoadingComplete) onStoreLoadingComplete()
    } catch (e) {
      setError(e.message)
    }
  }

  return (
    <div className='zsf-checkout-form' data-testid='zsf-checkout-form'>
      <form onSubmit={ handleSubmit(onSubmit) }>
        <textarea
          name='notes'
          className='notes'
          placeholder='Additional notes, allergies, cutlery, assistance, extras etc. (optional)'
          ref={ register }
        />
        <Pickup className='pickup'>Pickup in 30 min</Pickup>
        <div className='select-store'>
          <StoreSelector stores={ stores } selectedStore={ store } onChange={ handleStoreChange } />
        </div>
        {
          !!paymentRequest && (
            <div className='payment-request-wrapper'>
              <PaymentRequestButtonElement options={{paymentRequest}} onClick={ handlePaymentRequestClick } />
            </div>
          )
        }
        <SectionDivision className='division' text='or pay by card' />
        <div className='section-title'>Payment information</div>
        <div className='field'>
          <label htmlFor='card-element' className='label'>Card information</label>
          <div className='card-wrapper'>
            <CardNumberElement
              id="card-element"
              options={ cardStyle }
              showIcon
              onChange={ handleCardChange }
            />
          </div>
          <div className='card-wrapper two-cols'>
            <div>
              <CardExpiryElement
                options={ cardStyle }
                onChange={ handleCardChange }
              />
            </div>
            <div className='cvc'>
              <CardCvcElement
                options={ cardStyle }
                onChange={ handleCardChange }
              />
            </div>
          </div>
          { cardError && <div className='error'>{ cardError.message }</div> }
        </div>
        <div className={ `field ${errors.name && 'error'}` }>
          <label className='label' htmlFor='name'>Name on card</label>
          <input type='text' id='name' name='name' ref={ register(registerOptions.name) } />
          { errors.name && <div className='error'>{ errors.name.message }</div> }
        </div>
        <div className={ `field ${errors.zipcode && 'error'}` }>
          <label className='label' htmlFor='zipcode'>Zipcode</label>
          <input
            type='text'
            id='zipcode'
            name='zipcode'
            ref={ register(registerOptions.zipcode) }
          />
          { errors.zipcode && <div className='error'>{ errors.zipcode.message }</div> }
        </div>
        <div className={ `field ${errors.phone && 'error'}` }>
          <label className='label' htmlFor='phone'>Phone number</label>
          <input type='text' id='phone' name='phone' ref={ register(registerOptions.phone) } />
          { errors.phone && <div className='error'>{ errors.phone.message }</div> }
        </div>
        <div className={ `field ${errors.email && 'error'}` }>
          <label className='label' htmlFor='email'>Email address (optional)</label>
          <input type='text' id='email' name='email' ref={ register(registerOptions.email) } />
          { errors.email && <div className='error'>{ errors.email.message }</div> }
        </div>
        <Button
          text={ `Pay $${toCurrency(paymentData.total)}` }
          className='submit'
          onClick={ handleSubmit }
          loading={ submitting }
          disabled={ !formIsValid() }
        />
      </form>
      {
        !!error && (
          <Message type='error' onClose={ handleErrorClose }>
            { `Error: ${error}` }
          </Message>
        )
      }
    </div>
  )
}

CheckoutForm.propTypes = {
  paymentData: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  variant: PropTypes.object.isRequired,
  selectedQuantity: PropTypes.number.isRequired,
  onStoreChange: PropTypes.func,
  onPaymentComplete:PropTypes.func,
  onStoreLoading: PropTypes.func,
  onStoreLoadingComplete: PropTypes.func
}

export default CheckoutForm
