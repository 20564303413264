import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'

import { createPayment } from '../../services/checkout'
import { isStoreOpen } from '../../services/store'
import Header from '../Header'
import { toCurrency, getVariantName } from '../../utils'
import { EGAnalytics, analyticEvents } from '../../services/analytics'
import { fpData as getPriceData, stores } from '../../data'
import Checkout from '../Checkout'
import CheckoutSuccess from '../CheckoutSuccess'
import { RadioButtonGroup, QuantitySelector, Button } from '../FormComponents'
import StoreSelector from '../StoreSelector'
import Message from '../Message'

import OrderOption from './OrderOption'
import './styles.scss'

const CheckoutLanding = (props) => {
  const { hasDiscountAmount } = props
  const data = getPriceData(hasDiscountAmount)
  const [ quantity, setQuantity ] = useState(1)
  const [ selectedVariant, setSelectedVariant ] = useState(data.variants[0])
  const [ showNoDelivery, setShowNoDelivery ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ paymentData, setPaymentData ] = useState(null)
  const [ paymentCompleted, setPaymentCompleted ] = useState(false)
  const isStoreClose = !isStoreOpen()
  const [ selectedStore, setSelectedStore ] = useState(stores[0])
  const [ storeChanged, setStoreChanged ] = useState(false)
  const [ storeSelectorOpened, setStoreSelectorOpened] = useState(false)
  const [ hasMounted, setHasMounted ] = useState(false)
  const [ error, setError ] = useState(null)

  useEffect(() => {
    EGAnalytics.track(analyticEvents.ITEM_PAGE_VIEWED, {
      segmentPageName_: 'Item Page Viewed',
      itemName: data.name,
      itemUnitPrice: get(data.variants[0], 'price'),
      itemQty: 1,
      merchantName: selectedStore.name
    })
    setHasMounted(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onMenuImageClick = _ => {
    EGAnalytics.track(analyticEvents.ITEM_IMAGE_INTERACTION, {
      itemName: data.name,
      itemUnitPrice: selectedVariant.price,
      itemQty: quantity,
      merchantName: selectedStore.name,
    })
  }

  const handleQtyChange = qty => {
    if(hasDiscountAmount && qty > 5) {
      // Show notification that with discount max allowed quantity is 5.
      return
    }
    EGAnalytics.track(analyticEvents.ITEM_QUALITY_CHANGED, {
      itemName: data.name,
      itemUnitPrice: selectedVariant.price,
      itemQty: qty,
      merchantName: selectedStore.name,
      previousQty: quantity
    })
    setQuantity(parseInt(qty))
  }

  const handleOptionChange = (variant) => {
    const selectedOption = data.variants.find(v => getVariantName(v, hasDiscountAmount) === variant)
    EGAnalytics.track(analyticEvents.ITEM_MODIFIER_SELECTED, {
      itemName: data.name,
      itemUnitPrice: selectedOption.price,
      itemQty: quantity,
      itemModifierName: getVariantName(selectedOption, hasDiscountAmount),
      merchantName: selectedStore.name,
      itemPreviousModifierName: getVariantName(selectedVariant, hasDiscountAmount),
    })
    setSelectedVariant(selectedOption)
  }

  const handlePickupClick = async ({ storeConfirmed = false, store }) => {
    // just a etc. check to ensure that we are not submitting when store is not open.
    if (isStoreClose) return 

    if (!storeConfirmed && !storeChanged) {
      setStoreSelectorOpened(true)
      return
    }

    if (!store) {
      store = selectedStore
    }

    setLoading(true)
    
    EGAnalytics.track(analyticEvents.CHECKOUT_INITIATED, {
      itemName: data.name,
      itemUnitPrice: get(selectedVariant, 'price'),
      itemQty: quantity,
      orderDate: moment().format('MM/DD/YYYY'),
      orderSubtotal: parseFloat(parseFloat(selectedVariant.price * quantity).toFixed(2))
    })

    try {
      const res = await createPayment(selectedVariant.priceId, quantity, store)
      setPaymentData(res)
    } catch (e) {
      setError(e.message)
      setLoading(false)
    }
  }

  const onDeliveryOptionClick = () => {
    EGAnalytics.track(analyticEvents.DELIVERY_BUTTON_INTERACTION, {
      itemName: data.name,
      itemUnitPrice: selectedVariant.price,
      itemQty: quantity,
    })
    setShowNoDelivery(true)
    // Hide the warning after 2 seconds
    setTimeout(() => {
      setShowNoDelivery(false)
    }, 5000)
  }

  const handleStoreChange = (store, updatedTotals) => {
    setStoreSelectorOpened(false)
    setPaymentData(updatedTotals)
    if (store.uuid !== selectedStore.uuid) {
      setSelectedStore(store)
      setStoreChanged(true)
    }
    if (storeSelectorOpened) {
      handlePickupClick({ storeConfirmed: true, store })
    }
  }

  const handleStoreCancel = () => {
    setStoreSelectorOpened(false)
  }

  const handlePaymentComplete = () => {
    setPaymentCompleted(true)
  }

  if (paymentCompleted) {
    return (
      <CheckoutSuccess
        productData={ data }
        paymentData={ paymentData }
        variant={ selectedVariant }
        quantity={ quantity }
        store={ selectedStore }
      />
    )
  }

  if (paymentData) {
    return (
      <Checkout
        hasDiscountAmount={!!hasDiscountAmount}
        paymentData={ paymentData }
        variant={ selectedVariant }
        quantity={ quantity }
        onPaymentComplete={ handlePaymentComplete }
        onStoreChange={ handleStoreChange }
        store={ selectedStore }
      />
    )
  }

  // Fixes Gatsby rehydration issue. More details here:
  // https://www.joshwcomeau.com/react/the-perils-of-rehydration/
  if (!hasMounted) return null

  return (
    <div className='zsf-checkout-landing'>
      <Helmet>
        <title>{selectedStore.name} | Order Now</title>
      </Helmet>
      <Header title={ selectedStore.name } />
      <div
        data-testid='checkout-main-image'
        className='item-img-wrapper'
        style={ { backgroundImage: `url(${data.itemImg})` } }
        onClick={onMenuImageClick}
      >
        {/* TODO: nice to have
        <div className='expiring-wrapper'>
          Promo expiring in 08:32:31
        </div>
        */}
      </div>
      <div className='details'>
        <div className='title'>{ data.name }</div>
        <div className='price-container'>
          <div className='price'>${toCurrency(selectedVariant.price)}</div>
          { hasDiscountAmount && <strike className='main-price'>${ toCurrency(selectedVariant.mainPrice) }</strike>}
        </div>
        { (hasDiscountAmount && !isStoreClose) && <div className='discount-message'>${selectedVariant.discountAmount} discount applied to each pizza</div>}
        <div>
          { isStoreClose && <div className='close-message'>Closed at the moment. Available from 11:00 AM</div> }
        </div>
        {
          !isStoreClose && (
            <>
              <div className='order-option-container'>
                <OrderOption 
                  name= {`Pick up in ${data.pickupTime}`}
                  type= 'pick-up'
                  selected={true}
                  onClick={() => setShowNoDelivery(false)}
                />
                <OrderOption
                  name= 'Delivery'
                  type= 'delivery'
                  selected={false}
                  onClick={ onDeliveryOptionClick }
                />
              </div>
              { showNoDelivery && <div className='delivery-warning'>Delivery is currently not available <span data-testid='close-logo'onClick={() => setShowNoDelivery(false)} /></div> }
            </>
          )
        }
        <div className='select-store'>
          <StoreSelector
            stores={ stores }
            selectedStore={ selectedStore }
            opened={ storeSelectorOpened }
            onChange={ handleStoreChange }
            onCancel={ handleStoreCancel }
          />
        </div>
        <div className='items'>
          { data.items.join(', ') }
        </div>
        <div className='variants'>
          <div className='title'>Choose size</div>
          <RadioButtonGroup
            name='option'
            defaultValue={ getVariantName(selectedVariant, hasDiscountAmount) }
            options={ data.variants.map(v => ({
              name: getVariantName(v, hasDiscountAmount),
              value: getVariantName(v, hasDiscountAmount),
              label: getVariantName(v, hasDiscountAmount)
            })) }
            onChange={ handleOptionChange }
          />
        </div>

        <div className='footer-wrapper'>
          <div className='actions-wrapper'>
            <div className='actions'>
              <QuantitySelector quantity={ quantity } onChange={ handleQtyChange } />
              <Button
                text={`PICK UP`}
                className={`${isStoreClose && 'disable'}`}
                onClick={ handlePickupClick }
                loading={ loading }
                disabled={ !selectedVariant || isStoreClose }
              />
            </div>
          </div>
        </div>
        {
          !!error && (
            <Message type='error' onClose={ () => setError(false) }>
              { `Error: ${error}` }
            </Message>
          )
        }
      </div>
    </div>
  )
}

CheckoutLanding.propTypes = {
  discountAmount: PropTypes.number
}

export default CheckoutLanding
