import momentTimeZone from 'moment-timezone'

const STORE_TIME_ZONES = {
  NEW_HEAVEN: 'America/New_York'
}

const storeOpenHours = {
  OPEN: 11,
  CLOSE: 21,
}

let currentHour = momentTimeZone.tz(STORE_TIME_ZONES.NEW_HEAVEN).hours()
export const isStoreOpen = () =>  (storeOpenHours.OPEN <= currentHour ) && (currentHour < storeOpenHours.CLOSE)
