import React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'

import { dateUtils } from '../../utils'
import Pickup from '../Pickup'
import './styles.scss'

const OrderCard = props => {
  const { order, className = '', store } = props
  const date = new Date()
  date.setMinutes(date.getMinutes() + 30)
  const pickUpTime = date

  return (
    <div className={ `eg-card ${className}` }>
      <div className='title'>{ store.name }</div>
      <div className='number'>Order #{ order.code }</div>
      <div className='items'>
        {
          <div className='item'>
            <div className='img'>
              <StaticImage
                src='../../assets/fp-item-tomato.jpeg'
                className='logo'
                alt='logo'
                width={ 47 }
                height={ 48 }
              />
            </div>
            <div className='name'>{ order.quantity }x { order.description }</div>
            <div className='price'>${order.subtotal.toFixed(2)}</div>
          </div>
        }
      </div>
      <div className='tax'>
        <div>Tax</div>
        <div>${order.tax.toFixed(2)}</div>
      </div>
      <div className='total'>
        <div>Total</div>
        <div>${order.total.toFixed(2)}</div>
      </div>
      <div className='paid'>
        <div>Paid</div>
      </div>
      <div className='button'>
        <Pickup color='#d80904' className='pickup'>
          Pick up at { dateUtils.getTime(pickUpTime) }
        </Pickup>
      </div>
    </div>
  )
}

OrderCard.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
}

export default OrderCard
