import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const Loader = ({className = ''}) => {
  return (
    <div className={ `idsEllipsis ${className}` }>
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
  )
}

Loader.propTypes = {
  className: PropTypes.string,
}

export default Loader
