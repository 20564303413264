import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const OrderOption = props => {
  const { type, name, selected, onClick } = props
  
  const onContainerClick = () => {
    if(onClick) {
      onClick()
    }
  }

  return (
    <div className={`eg-order-option ${selected ? 'selected' : ''}`} onClick={onContainerClick}>
      <div className={type} />
      <label>{name}</label>
    </div>
  )
}

OrderOption.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func
}

export default OrderOption
