import React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'

import './styles.scss'

const Header = props => {
  const { title } = props
  
  const goToHomepage = () => {
    window.location.assign('/')
  }

  return (
    <div className='header' onClick={goToHomepage}>
      <StaticImage src='../../assets/fp-logo.png' className='logo' alt='logo' />
      <div className='title'>{ title }</div>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string
}

export default Header
