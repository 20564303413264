// Generated by Avo VERSION 83.101.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* eslint-disable */

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function() {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function(obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items) {
    var iterator = {
      next: function() {
        var value = items.shift();
        return {done: value === undefined, value: value}
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function() {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers) {
    this.map = {};

    if (headers instanceof Headers) {
      headers.forEach(function(value, name) {
        this.append(name, value);
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function(header) {
        this.append(header[0], header[1]);
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function(name) {
        this.append(name, headers[name]);
      }, this);
    }
  }

  Headers.prototype.append = function(name, value) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function(name) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function(name) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function(name) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function(name, value) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function(callback, thisArg) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function() {
    var items = [];
    this.forEach(function(value) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body) {
    if (body.bodyUsed) {
      return Promise.reject(new TypeError('Already read'))
    }
    body.bodyUsed = true;
  }

  function fileReaderReady(reader) {
    return new Promise(function(resolve, reject) {
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.onerror = function() {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]);
    }
    return chars.join('')
  }

  function bufferClone(buf) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    this.bodyUsed = false;

    this._initBody = function(body) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      this.blob = function() {
        var rejected = consumed(this);
        if (rejected) {
          return rejected
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob)
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]))
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob')
        } else {
          return Promise.resolve(new Blob([this._bodyText]))
        }
      };

      this.arrayBuffer = function() {
        if (this._bodyArrayBuffer) {
          return consumed(this) || Promise.resolve(this._bodyArrayBuffer)
        } else {
          return this.blob().then(readBlobAsArrayBuffer)
        }
      };
    }

    this.text = function() {
      var rejected = consumed(this);
      if (rejected) {
        return rejected
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob)
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer))
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text')
      } else {
        return Promise.resolve(this._bodyText)
      }
    };

    if (support.formData) {
      this.formData = function() {
        return this.text().then(decode)
      };
    }

    this.json = function() {
      return this.text().then(JSON.parse)
    };

    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input, options) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if (input.bodyUsed) {
        throw new TypeError('Already read')
      }
      this.url = input.url;
      this.credentials = input.credentials;
      if (!options.headers) {
        this.headers = new Headers(input.headers);
      }
      this.method = input.method;
      this.mode = input.mode;
      this.signal = input.signal;
      if (!body && input._bodyInit != null) {
        body = input._bodyInit;
        input.bodyUsed = true;
      }
    } else {
      this.url = String(input);
    }

    this.credentials = options.credentials || this.credentials || 'same-origin';
    if (options.headers || !this.headers) {
      this.headers = new Headers(options.headers);
    }
    this.method = normalizeMethod(options.method || this.method || 'GET');
    this.mode = options.mode || this.mode || null;
    this.signal = options.signal || this.signal;
    this.referrer = null;

    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    this._initBody(body);
  }

  Request.prototype.clone = function() {
    return new Request(this, {body: this._bodyInit})
  };

  function decode(body) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function(bytes) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders) {
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function(line) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit, options) {
    if (!options) {
      options = {};
    }

    this.type = 'default';
    this.status = options.status === undefined ? 200 : options.status;
    this.ok = this.status >= 200 && this.status < 300;
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    this.headers = new Headers(options.headers);
    this.url = options.url || '';
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function() {
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function() {
    var response = new Response(null, {status: 0, statusText: ''});
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function(url, status) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    return new Response(null, {status: status, headers: {location: url}})
  };

  self.DOMException = self.DOMException;
  try {
    new self.DOMException();
  } catch (err) {
    self.DOMException = function(message, name) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    self.DOMException.prototype = Object.create(Error.prototype);
    self.DOMException.prototype.constructor = self.DOMException;
  }

  function fetch(input, init) {
    return new Promise(function(resolve, reject) {
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new self.DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function() {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        options.url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : xhr.responseText;
        resolve(new Response(body, options));
      };

      xhr.onerror = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function() {
        reject(new self.DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function(value, name) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function() {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    self.fetch = fetch;
    self.Headers = Headers;
    self.Request = Request;
    self.Response = Response;
  }
})();

(function(exports) {
  var __AVO_DEV__ = false;
  var __AVO_NOOP__ = false;
  var __AVO_LOGGER__ = null;
  var __AVO_ENV__ = null;
  var __REPORT_FAILURE_AS__ = null;
  var __STRICT__ = null;

  var __WEB_DEBUGGER__ = true;
  const avoInspectorApiKey = "6685AC2uNE6mMQLHtPDe";
  var __INSPECTOR__ = null;

  // polyfill Array.isArray
  if (!Array.isArray) {
    Array.isArray = function(arg) {
      return Object.prototype.toString.call(arg) === '[object Array]';
    };
  }

  // polyfill Object.assign
  if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
      value: function assign(target, varArgs) { // .length of function is 2
        if (target == null) { // TypeError if undefined or null
          throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource != null) { // Skip over if undefined or null
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }

  // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
  if (!Object.keys) {
    Object.keys = (function() {
      'use strict';
      var hasOwnProperty = Object.prototype.hasOwnProperty,
          hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
          dontEnums = [
            'toString',
            'toLocaleString',
            'valueOf',
            'hasOwnProperty',
            'isPrototypeOf',
            'propertyIsEnumerable',
            'constructor'
          ],
          dontEnumsLength = dontEnums.length;

      return function(obj) {
        if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
          throw new TypeError('Object.keys called on non-object');
        }

        var result = [], prop, i;

        for (prop in obj) {
          if (hasOwnProperty.call(obj, prop)) {
            result.push(prop);
          }
        }

        if (hasDontEnumBug) {
          for (i = 0; i < dontEnumsLength; i++) {
            if (hasOwnProperty.call(obj, dontEnums[i])) {
              result.push(dontEnums[i]);
            }
          }
        }
        return result;
      };
    }());
  }

  // polyfill Array.indexOf
  if (!Array.prototype.indexOf)  Array.prototype.indexOf = (function(Object, max, min){
    "use strict";
    return function indexOf(member, fromIndex) {
      if(this===null||this===undefined)throw TypeError("Array.prototype.indexOf called on null or undefined");

      var that = Object(this), Len = that.length >>> 0, i = min(fromIndex | 0, Len);
      if (i < 0) i = max(0, Len+i); else if (i >= Len) return -1;

      if(member===void 0){ for(; i !== Len; ++i) if(that[i]===void 0 && i in that) return i; // undefined
      }else if(member !== member){   for(; i !== Len; ++i) if(that[i] !== that[i]) return i; // NaN
      }else                           for(; i !== Len; ++i) if(that[i] === member) return i; // all else

      return -1; // if the value was not found, then return -1
    };
  })(Object, Math.max, Math.min);

  var array_difference, AvoAssert, InternalAvoLogger;
  array_difference = function array_difference(a1, a2) {
    var result = [];
    for (var i = 0; i < a1.length; i++) {
      if (a2.indexOf(a1[i]) === -1) {
        result.push(a1[i]);
      }
    }
    return result;
  }

  AvoAssert = {
    assertObject: function assertObject(propertyId, propName, obj) {
      if (typeof obj !== 'object') {
        var message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
        return [{tag: 'expectedObjectType', propertyId: propertyId, message: message, actualType: typeof obj}];
      } else {
        return [];
      }
    },

    assertString: function assertString(propertyId, propName, str) {
      if (typeof str !== 'string') {
        var message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
        return [{tag: 'expectedStringType', propertyId: propertyId, message: message, actualType: typeof str}];
      } else {
        return [];
      }
    },

    assertInt: function assertInt(propertyId, propName, int) {
      if (typeof int === 'number' && int !== Math.round(int)) {
        var message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
        return [{tag: 'expectedIntType', propertyId: propertyId, actualType: 'float'}];
      } else if (typeof int !== 'number') {
        var message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
        return [{tag: 'expectedIntType', propertyId: propertyId, message: message, actualType: typeof int}];
      } else {
        return [];
      }
    },

    assertLong: function assertLong(propertyId, propName, long) {
      if (typeof long === 'number' && long !== Math.round(long)) {
        var message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
        return [{tag: 'expectedLongType', propertyId: propertyId, actualType: 'float'}];
      } else if (typeof long !== 'number') {
        var message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
        return [{tag: 'expectedLongType', propertyId: propertyId, message: message, actualType: typeof long}];
      } else {
        return [];
      }
    },

    assertFloat: function assertFloat(propertyId, propName, float) {
      if (typeof float !== 'number') {
        var message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
        return [{tag: 'expectedFloatType', propertyId: propertyId, message: message, actualType: typeof float}];
      } else {
        return [];
      }
    },

    assertBool: function assertBool(propertyId, propName, bool) {
      if (typeof bool !== 'boolean') {
        var message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
        return [{tag: 'expectedBoolType', propertyId: propertyId, message: message, actualType: typeof bool}];
      } else {
        return [];
      }
    },

    assertMax: function assertMax(propertyId, propName, max, value) {
      if (value > max) {
        var message = propName +
          ' has a maximum value of ' +
          max +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{tag: 'expectedMax', propertyId: propertyId, message: message}];
      } else {
        return [];
      }
    },

    assertMin: function assertMin(propertyId, propName, min, value) {
      if (value < min) {
        var message = propName +
          ' has a minimum value of ' +
          min +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{tag: 'expectedMin', propertyId: propertyId, message: message}];
      } else {
        return [];
      }
    },

    assertList: function assertList(propertyId, propName, value) {
      if (!Array.isArray(value)) {
        var message = propName + ' should be of type list but you provided type ' + typeof value;
        return [{tag: 'expectedList', propertyId: propertyId, message: message, actualType: typeof value}];
      } else {
        return [];
      }
    },

    assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
      } else {
        return [];
      }
    },

    assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
      } else {
        return [];
      }
    }
  };

  InternalAvoLogger = {
    logEventSent: function logEventSent(eventName, eventProperties, userProperties) {
      const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

      if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
    },

    log: function log(message) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.log("[avo] " + message);
    },

    warn: function warn(message) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.warn("[avo] " + message);
    },

    error: function error(message, error) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
        return
      }
      typeof console !== 'undefined' && console.error("[avo] " + message, error);
    }
  };

  var _avo_invoke, _avo_invoke_meta;
var _avo_sampling_rate = 1.0;
function _avo_invoke_payload(body) {
  if (typeof window === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body)
      }).then(function(res) { return res.json(); }).then(function(data) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke = function _avo_invoke(env, eventId, hash, messages, origin) {
  _avo_invoke_payload({
    "ac": "GM3svL4baBOFLwh8ntJE",
    "br": "master",
    "en": env,
    "ev": eventId,
    "ha": hash,
    "sc": "ZMwnebQikC6pCSNEAgJH",
    "se": (new Date()).toISOString(),
    "so": "gmLaUvaNV",
    "va": messages.length === 0,
    "me": messages,
    "or": origin
  });
}

_avo_invoke_meta = function _avo_invoke_meta(env, type, messages, origin) {
  _avo_invoke_payload({
    "ac": "GM3svL4baBOFLwh8ntJE",
    "br": "master",
    "en": env,
    "ty": type,
    "sc": "ZMwnebQikC6pCSNEAgJH",
    "se": (new Date()).toISOString(),
    "so": "gmLaUvaNV",
    "va": messages.length === 0,
    "me": messages,
    "or": origin
  });
}


  var _avo_debugger_log;
var _avo_debugger_events_during_boot = [];
var _avo_debugger_ready = false;

if (typeof window !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    var iframe = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      var message = {
        type_: "avo-debugger-boot-events",
        schemaId: "ZMwnebQikC6pCSNEAgJH",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId, eventName, messages, eventProperties, userProperties) {
  if (typeof window === 'undefined') { return; }
  var event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties: eventProperties,
    userProperties: userProperties
  };

  if (_avo_debugger_ready) {
    var message = {type_: "avo-debugger-events", events: [event]};
    document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}


var SegmentZSFCheckoutV1 = {
  make: function make(apiKey, options) {
    if (typeof window === 'undefined') { InternalAvoLogger.warn('window.analytics is not available in Node.js'); return; }
    var analytics = window.analytics = window.analytics || [];
    if (analytics.initialize) return;
    if (analytics.invoked) {
      if (InternalAvoLogger && InternalAvoLogger.error) {
        InternalAvoLogger.error('Segment snippet included twice.');
      }
      return;
    }
    analytics.invoked = true;
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on'
    ];
    analytics.factory = function(method){
      return function(){
        var args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };
    for (var i = 0; i < analytics.methods.length; i++) {
      var key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }
    analytics.load = function(key, options){
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.segment.com/analytics.js/v1/'
        + key + '/analytics.min.js';
      var first = document.getElementsByTagName('script')[0];
      first.parentNode.insertBefore(script, first);
      analytics._loadOptions = options;
    };
    analytics.SNIPPET_VERSION = '4.1.0';

    analytics.load(apiKey, options);
  },

  /* Segment does not have revenue api, @see https://segment.com/docs/spec/track/#properties */
  logEvent: function logEvent(eventName, eventProperties, integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.track(eventName, eventProperties, Object.assign({}, {integrations: integrations}));
  },

  setUserProperties: function setUserProperties(userProperties, integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.identify(userProperties, Object.assign({}, {integrations: integrations}));
  },

  identify: function identify(userId, integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.identify(userId, {}, Object.assign({}, {integrations: integrations}));
  },

  unidentify: function unidentify(integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.identify(null, {}, Object.assign({}, {integrations: integrations}));
  },

  page: function page(eventName, eventProperties, integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.page(eventName, eventProperties, Object.assign({}, {integrations: integrations}));
  },
};

  function assertOrderSubtotal(orderSubtotal, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertFloat("2AN5ba0LeC", label_ ? 'order_subtotal' + ': ' + label_ : 'order_subtotal', orderSubtotal));
    return messages;
  }

  function assertOrderSalesTax(orderSalesTax, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertFloat("2Cu4pNbef4", label_ ? 'order_sales_tax' + ': ' + label_ : 'order_sales_tax', orderSalesTax));
    return messages;
  }

  function assertItemName(itemName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("BcaLOschrV", label_ ? 'item_name' + ': ' + label_ : 'item_name', itemName));
    return messages;
  }

  function assertPreviousQty(previousQty, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertInt("GKkTsrUtUi", label_ ? 'previous_qty' + ': ' + label_ : 'previous_qty', previousQty));
    return messages;
  }

  function assertItemQty(itemQty, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertInt("HA4Uq7iqYi", label_ ? 'item_qty' + ': ' + label_ : 'item_qty', itemQty));
    return messages;
  }

  function assertOptionalItemModifierName(itemModifierName, label_) {
    var messages = [];
    if (itemModifierName !== undefined && itemModifierName !== null) {
      messages = messages.concat(AvoAssert.assertString("QiAliQO7QY", label_ ? 'item_modifier_name' + ': ' + label_ : 'item_modifier_name', itemModifierName));
    }
    return messages;
  }

  function assertItemModifierName(itemModifierName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("QiAliQO7QY", label_ ? 'item_modifier_name' + ': ' + label_ : 'item_modifier_name', itemModifierName));
    return messages;
  }

  function assertItemUnitPrice(itemUnitPrice, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertFloat("S7Y1CVnA3B", label_ ? 'item_unit_price' + ': ' + label_ : 'item_unit_price', itemUnitPrice));
    return messages;
  }

  function assertOptionalOrderDate(orderDate, label_) {
    var messages = [];
    if (orderDate !== undefined && orderDate !== null) {
      messages = messages.concat(AvoAssert.assertString("_I2CSqb6CR", label_ ? 'order_date' + ': ' + label_ : 'order_date', orderDate));
    }
    return messages;
  }

  function assertMerchantName(merchantName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("aEac8fJg-8", label_ ? 'merchant_name' + ': ' + label_ : 'merchant_name', merchantName));
    return messages;
  }

  function assertSegmentPageName_(segmentPageName_, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("avo-enriched-type-page-segment-name", label_ ? 'Segment: Page Name' + ': ' + label_ : 'Segment: Page Name', segmentPageName_));
    return messages;
  }

  function assertOptionalOrderTotal(orderTotal, label_) {
    var messages = [];
    if (orderTotal !== undefined && orderTotal !== null) {
      messages = messages.concat(AvoAssert.assertFloat("k2b1UHU8jd", label_ ? 'order_total' + ': ' + label_ : 'order_total', orderTotal));
    }
    return messages;
  }

  function assertItemPreviousModifierName(itemPreviousModifierName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("tjMvffaLOB", label_ ? 'item_previous_modifier_name' + ': ' + label_ : 'item_previous_modifier_name', itemPreviousModifierName));
    return messages;
  }

  function setAvoLogger(avoLogger) {
    __AVO_LOGGER__ = avoLogger;
  }

  function setup_(options, systemProperties, destinationOptions) {
    __WEB_DEBUGGER__ = false;
    if (options.validateProperties === true) {
      __AVO_DEV__ = true;
    }

    destinationOptions = destinationOptions || {};

    if (!__AVO_NOOP__) {
      if (options.useProductionKey) {
        SegmentZSFCheckoutV1.make(
          "YBB7lfO1yootGSERDJR5ayN6yCM3AeBw",
          destinationOptions.segmentZsfCheckoutV1
        );
      } else {
        SegmentZSFCheckoutV1.make(
          "kEFxG1jNMAI1APWiUL5AFbRGHLZJrwOK",
          destinationOptions.segmentZsfCheckoutV1
        );
      }
      if (__AVO_DEV__) {
        // debug console in Avo
        _avo_invoke_meta(__AVO_ENV__, 'setup', [], 'setup');
      }
    }
  }

  function initAvo(options, systemProperties, destinationOptions) {
    if (__AVO_ENV__ !== null) {
      return;
    }
    __AVO_ENV__ = options.env.toLowerCase();
    if (options.avoLogger !== undefined) {
      __AVO_LOGGER__ = options.avoLogger;
    }
    if (options.noop === true) {
      __AVO_NOOP__ = true;
    }
    if (__AVO_NOOP__ && __AVO_ENV__ == 'prod') {
      InternalAvoLogger.warn("****************************************************");
      InternalAvoLogger.warn("WARNING Avo cannot be initialized in noop mode in production:");
      InternalAvoLogger.warn("- Overwriting configuration with noop=false.");
      InternalAvoLogger.warn("- Please reach out if you want to be able to run Avo in production mode with noop=true");
      InternalAvoLogger.warn("****************************************************");
      __AVO_NOOP__ = false;
    }
    if (__AVO_NOOP__) {
      InternalAvoLogger.log("****************************************************");
      InternalAvoLogger.log("Avo is now initialized in noop mode. This means:");
      InternalAvoLogger.log("- No events will be sent");
      InternalAvoLogger.log("- No network requests are made");
      InternalAvoLogger.log("****************************************************");
    }
    if (options.strict !== undefined) {
      __STRICT__ = options.strict !== false;
    }
    if (options.reportFailureAs !== undefined) {
      __REPORT_FAILURE_AS__ = options.reportFailureAs;
    }
    __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && window.location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== 'prod'));
    if (!__AVO_NOOP__ && options.inspector !== undefined) {
      __INSPECTOR__ = options.inspector;
    } else if (__AVO_ENV__ !== 'prod') {
      InternalAvoLogger.warn("Avo Inspector not provided in initAvo() call");
    }
    if (__AVO_ENV__ !== 'prod') {
      __AVO_DEV__ = true;
    }

    destinationOptions = destinationOptions || {};

    if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
(function() {
  if (typeof window === 'undefined') { return; }
  var init = function() {
    if (document.getElementById("avo-debugger") === null) {
      var iframe = document.createElement("iframe");
      document.body.appendChild(iframe);
      iframe.id = "avo-debugger";
      iframe.src = "https://www.avo.app/_debugger";
      iframe.style = "display: none;";
    }
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

    }
    if (!__AVO_NOOP__) {
      if (__AVO_ENV__ === 'prod') {
        SegmentZSFCheckoutV1.make(
          "YBB7lfO1yootGSERDJR5ayN6yCM3AeBw",
          destinationOptions.segmentZsfCheckoutV1
        );
      }
      if (__AVO_ENV__ === 'dev') {
        SegmentZSFCheckoutV1.make(
          "kEFxG1jNMAI1APWiUL5AFbRGHLZJrwOK",
          destinationOptions.segmentZsfCheckoutV1
        );
      }
      if (__AVO_DEV__) {
        // debug console in Avo
        _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
      }
    }
  }

  /**
   * Item Page Viewed: Sent when the menu item page (home page of the checkout site) is viewed
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.itemName - no description
   * @param {float} properties.itemUnitPrice - no description
   * @param {int} properties.itemQty - no description
   * @param {string} properties.itemModifierName - no description
   * @param {string} properties.merchantName - no description
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/wKU7fIIZoU}
   */
  function itemPageViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertItemName(properties.itemName));
      messages = messages.concat(assertItemUnitPrice(properties.itemUnitPrice));
      messages = messages.concat(assertItemQty(properties.itemQty));
      messages = messages.concat(assertOptionalItemModifierName(properties.itemModifierName));
      messages = messages.concat(assertMerchantName(properties.merchantName));
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Item Page Viewed", Object.keys(properties), [
        "itemName",
        "itemUnitPrice",
        "itemQty",
        "itemModifierName",
        "merchantName",
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "wKU7fIIZoU", "d17cad5ba96ba89a2f1295ce2e810e6ed39b2d3728886734865aae5ad353f2c6", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Item Page Viewed", {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        "merchant_name": properties.merchantName,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("wKU7fIIZoU", "Item Page Viewed", messages, [
        {id: "BcaLOschrV", name: "item_name", value: properties.itemName},
        {id: "HA4Uq7iqYi", name: "item_qty", value: properties.itemQty},
        {id: "QiAliQO7QY", name: "item_modifier_name", value: properties.itemModifierName},
        {id: "S7Y1CVnA3B", name: "item_unit_price", value: properties.itemUnitPrice},
        {id: "aEac8fJg-8", name: "merchant_name", value: properties.merchantName},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Item Page Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Item Page Viewed", {
          "item_name": properties.itemName,
          "item_unit_price": properties.itemUnitPrice,
          "item_qty": properties.itemQty,
          "item_modifier_name": properties.itemModifierName,
          "merchant_name": properties.merchantName,
          }, "wKU7fIIZoU", "d17cad5ba96ba89a2f1295ce2e810e6ed39b2d3728886734865aae5ad353f2c6");
      }
      // destination SegmentZSFCheckoutV1
      SegmentZSFCheckoutV1.page(properties.segmentPageName_, {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        "merchant_name": properties.merchantName,
        }, {});
      SegmentZSFCheckoutV1.logEvent("Item Page Viewed", {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        "merchant_name": properties.merchantName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Item Image Interaction: Sent when the user interacts (click, tap etc) with the menu item image
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.itemName - no description
   * @param {float} properties.itemUnitPrice - no description
   * @param {int} properties.itemQty - no description
   * @param {string} properties.itemModifierName - no description
   * @param {string} properties.merchantName - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/3llDDi7X9U}
   */
  function itemImageInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertItemName(properties.itemName));
      messages = messages.concat(assertItemUnitPrice(properties.itemUnitPrice));
      messages = messages.concat(assertItemQty(properties.itemQty));
      messages = messages.concat(assertOptionalItemModifierName(properties.itemModifierName));
      messages = messages.concat(assertMerchantName(properties.merchantName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Item Image Interaction", Object.keys(properties), [
        "itemName",
        "itemUnitPrice",
        "itemQty",
        "itemModifierName",
        "merchantName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "3llDDi7X9U", "9559835125904510cdb9b0d45c1215176fbfccd6975557ea701d2631a080f3b7", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Item Image Interaction", {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        "merchant_name": properties.merchantName,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("3llDDi7X9U", "Item Image Interaction", messages, [
        {id: "BcaLOschrV", name: "item_name", value: properties.itemName},
        {id: "HA4Uq7iqYi", name: "item_qty", value: properties.itemQty},
        {id: "QiAliQO7QY", name: "item_modifier_name", value: properties.itemModifierName},
        {id: "S7Y1CVnA3B", name: "item_unit_price", value: properties.itemUnitPrice},
        {id: "aEac8fJg-8", name: "merchant_name", value: properties.merchantName},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Item Image Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Item Image Interaction", {
          "item_name": properties.itemName,
          "item_unit_price": properties.itemUnitPrice,
          "item_qty": properties.itemQty,
          "item_modifier_name": properties.itemModifierName,
          "merchant_name": properties.merchantName,
          }, "3llDDi7X9U", "9559835125904510cdb9b0d45c1215176fbfccd6975557ea701d2631a080f3b7");
      }
      // destination SegmentZSFCheckoutV1
      SegmentZSFCheckoutV1.logEvent("Item Image Interaction", {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        "merchant_name": properties.merchantName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Item Quantity Changed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.itemName - no description
   * @param {float} properties.itemUnitPrice - no description
   * @param {int} properties.itemQty - no description
   * @param {string} properties.itemModifierName - no description
   * @param {string} properties.merchantName - no description
   * @param {int} properties.previousQty - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/p3d7nLhG9w}
   */
  function itemQuantityChanged(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertItemName(properties.itemName));
      messages = messages.concat(assertItemUnitPrice(properties.itemUnitPrice));
      messages = messages.concat(assertItemQty(properties.itemQty));
      messages = messages.concat(assertOptionalItemModifierName(properties.itemModifierName));
      messages = messages.concat(assertMerchantName(properties.merchantName));
      messages = messages.concat(assertPreviousQty(properties.previousQty));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Item Quantity Changed", Object.keys(properties), [
        "itemName",
        "itemUnitPrice",
        "itemQty",
        "itemModifierName",
        "merchantName",
        "previousQty"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "p3d7nLhG9w", "174b43542a5f43198adc74bd2cba7cae531e3183e5f25e197341bea71e0d2487", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Item Quantity Changed", {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        "merchant_name": properties.merchantName,
        "previous_qty": properties.previousQty,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("p3d7nLhG9w", "Item Quantity Changed", messages, [
        {id: "BcaLOschrV", name: "item_name", value: properties.itemName},
        {id: "GKkTsrUtUi", name: "previous_qty", value: properties.previousQty},
        {id: "HA4Uq7iqYi", name: "item_qty", value: properties.itemQty},
        {id: "QiAliQO7QY", name: "item_modifier_name", value: properties.itemModifierName},
        {id: "S7Y1CVnA3B", name: "item_unit_price", value: properties.itemUnitPrice},
        {id: "aEac8fJg-8", name: "merchant_name", value: properties.merchantName},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Item Quantity Changed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Item Quantity Changed", {
          "item_name": properties.itemName,
          "item_unit_price": properties.itemUnitPrice,
          "item_qty": properties.itemQty,
          "item_modifier_name": properties.itemModifierName,
          "merchant_name": properties.merchantName,
          "previous_qty": properties.previousQty,
          }, "p3d7nLhG9w", "174b43542a5f43198adc74bd2cba7cae531e3183e5f25e197341bea71e0d2487");
      }
      // destination SegmentZSFCheckoutV1
      SegmentZSFCheckoutV1.logEvent("Item Quantity Changed", {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        "merchant_name": properties.merchantName,
        "previous_qty": properties.previousQty,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Item Modifier Selected: Sent when the user selects an item modifier
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.itemName - no description
   * @param {float} properties.itemUnitPrice - no description
   * @param {int} properties.itemQty - no description
   * @param {string} properties.itemModifierName - no description
   * @param {string} properties.merchantName - no description
   * @param {string} properties.itemPreviousModifierName - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/EhuD7KlruZ}
   */
  function itemModifierSelected(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertItemName(properties.itemName));
      messages = messages.concat(assertItemUnitPrice(properties.itemUnitPrice));
      messages = messages.concat(assertItemQty(properties.itemQty));
      messages = messages.concat(assertItemModifierName(properties.itemModifierName));
      messages = messages.concat(assertMerchantName(properties.merchantName));
      messages = messages.concat(assertItemPreviousModifierName(properties.itemPreviousModifierName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Item Modifier Selected", Object.keys(properties), [
        "itemName",
        "itemUnitPrice",
        "itemQty",
        "itemModifierName",
        "merchantName",
        "itemPreviousModifierName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "EhuD7KlruZ", "d86df967f4158a48b9ab77caab6fc09aa5a1cfb4b84ecf02b6f63a26c0439051", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Item Modifier Selected", {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        "merchant_name": properties.merchantName,
        "item_previous_modifier_name": properties.itemPreviousModifierName,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("EhuD7KlruZ", "Item Modifier Selected", messages, [
        {id: "BcaLOschrV", name: "item_name", value: properties.itemName},
        {id: "HA4Uq7iqYi", name: "item_qty", value: properties.itemQty},
        {id: "QiAliQO7QY", name: "item_modifier_name", value: properties.itemModifierName},
        {id: "S7Y1CVnA3B", name: "item_unit_price", value: properties.itemUnitPrice},
        {id: "aEac8fJg-8", name: "merchant_name", value: properties.merchantName},
        {id: "tjMvffaLOB", name: "item_previous_modifier_name", value: properties.itemPreviousModifierName},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Item Modifier Selected': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Item Modifier Selected", {
          "item_name": properties.itemName,
          "item_unit_price": properties.itemUnitPrice,
          "item_qty": properties.itemQty,
          "item_modifier_name": properties.itemModifierName,
          "merchant_name": properties.merchantName,
          "item_previous_modifier_name": properties.itemPreviousModifierName,
          }, "EhuD7KlruZ", "d86df967f4158a48b9ab77caab6fc09aa5a1cfb4b84ecf02b6f63a26c0439051");
      }
      // destination SegmentZSFCheckoutV1
      SegmentZSFCheckoutV1.logEvent("Item Modifier Selected", {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        "merchant_name": properties.merchantName,
        "item_previous_modifier_name": properties.itemPreviousModifierName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Checkout Initiated: Sent when the user initiates checkout i.e. just before they're redirected to stripe
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.itemName - no description
   * @param {float} properties.itemUnitPrice - no description
   * @param {int} properties.itemQty - no description
   * @param {string} properties.itemModifierName - no description
   * @param {string} properties.orderDate - no description
   * @param {float} properties.orderSubtotal - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/NkMuCaQZOf}
   */
  function checkoutInitiated(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertItemName(properties.itemName));
      messages = messages.concat(assertItemUnitPrice(properties.itemUnitPrice));
      messages = messages.concat(assertItemQty(properties.itemQty));
      messages = messages.concat(assertOptionalItemModifierName(properties.itemModifierName));
      messages = messages.concat(assertOptionalOrderDate(properties.orderDate));
      messages = messages.concat(assertOrderSubtotal(properties.orderSubtotal));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Checkout Initiated", Object.keys(properties), [
        "itemName",
        "itemUnitPrice",
        "itemQty",
        "itemModifierName",
        "orderDate",
        "orderSubtotal"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "NkMuCaQZOf", "181c32eed181ba5101620b1de2392e3c0a672506f3ff29976b97969ca7109be4", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Checkout Initiated", {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        "order_date": properties.orderDate,
        "order_subtotal": properties.orderSubtotal,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("NkMuCaQZOf", "Checkout Initiated", messages, [
        {id: "2AN5ba0LeC", name: "order_subtotal", value: properties.orderSubtotal},
        {id: "BcaLOschrV", name: "item_name", value: properties.itemName},
        {id: "HA4Uq7iqYi", name: "item_qty", value: properties.itemQty},
        {id: "QiAliQO7QY", name: "item_modifier_name", value: properties.itemModifierName},
        {id: "S7Y1CVnA3B", name: "item_unit_price", value: properties.itemUnitPrice},
        {id: "_I2CSqb6CR", name: "order_date", value: properties.orderDate},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Checkout Initiated': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Checkout Initiated", {
          "item_name": properties.itemName,
          "item_unit_price": properties.itemUnitPrice,
          "item_qty": properties.itemQty,
          "item_modifier_name": properties.itemModifierName,
          "order_date": properties.orderDate,
          "order_subtotal": properties.orderSubtotal,
          }, "NkMuCaQZOf", "181c32eed181ba5101620b1de2392e3c0a672506f3ff29976b97969ca7109be4");
      }
      // destination SegmentZSFCheckoutV1
      SegmentZSFCheckoutV1.logEvent("Checkout Initiated", {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        "order_date": properties.orderDate,
        "order_subtotal": properties.orderSubtotal,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Delivery Button Interaction: Sent when the user interacts with the Delivery button
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.itemName - no description
   * @param {float} properties.itemUnitPrice - no description
   * @param {int} properties.itemQty - no description
   * @param {string} properties.itemModifierName - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/iQmg-fQbOF}
   */
  function deliveryButtonInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertItemName(properties.itemName));
      messages = messages.concat(assertItemUnitPrice(properties.itemUnitPrice));
      messages = messages.concat(assertItemQty(properties.itemQty));
      messages = messages.concat(assertOptionalItemModifierName(properties.itemModifierName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Delivery Button Interaction", Object.keys(properties), [
        "itemName",
        "itemUnitPrice",
        "itemQty",
        "itemModifierName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "iQmg-fQbOF", "4ff6cc288292b5272335c2b0328facb46be88ef3211ee1c095fe71eb379d0c8d", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Delivery Button Interaction", {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("iQmg-fQbOF", "Delivery Button Interaction", messages, [
        {id: "BcaLOschrV", name: "item_name", value: properties.itemName},
        {id: "HA4Uq7iqYi", name: "item_qty", value: properties.itemQty},
        {id: "QiAliQO7QY", name: "item_modifier_name", value: properties.itemModifierName},
        {id: "S7Y1CVnA3B", name: "item_unit_price", value: properties.itemUnitPrice},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Delivery Button Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Delivery Button Interaction", {
          "item_name": properties.itemName,
          "item_unit_price": properties.itemUnitPrice,
          "item_qty": properties.itemQty,
          "item_modifier_name": properties.itemModifierName,
          }, "iQmg-fQbOF", "4ff6cc288292b5272335c2b0328facb46be88ef3211ee1c095fe71eb379d0c8d");
      }
      // destination SegmentZSFCheckoutV1
      SegmentZSFCheckoutV1.logEvent("Delivery Button Interaction", {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Checkout Completed: Sent when the user finishes checkout i.e. is redirected to the confirmation page
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.itemName - no description
   * @param {float} properties.itemUnitPrice - no description
   * @param {int} properties.itemQty - no description
   * @param {string} properties.itemModifierName - no description
   * @param {float} properties.orderSubtotal - no description
   * @param {float} properties.orderTotal - no description
   * @param {float} properties.orderSalesTax - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/D7tRpSNvwf}
   */
  function checkoutCompleted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertItemName(properties.itemName));
      messages = messages.concat(assertItemUnitPrice(properties.itemUnitPrice));
      messages = messages.concat(assertItemQty(properties.itemQty));
      messages = messages.concat(assertOptionalItemModifierName(properties.itemModifierName));
      messages = messages.concat(assertOrderSubtotal(properties.orderSubtotal));
      messages = messages.concat(assertOptionalOrderTotal(properties.orderTotal));
      messages = messages.concat(assertOrderSalesTax(properties.orderSalesTax));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Checkout Completed", Object.keys(properties), [
        "itemName",
        "itemUnitPrice",
        "itemQty",
        "itemModifierName",
        "orderSubtotal",
        "orderTotal",
        "orderSalesTax"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "D7tRpSNvwf", "33f5b61a8f7ce7d8f4e5cd932c4791783f60aa3b32eaa859371fd394424be031", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Checkout Completed", {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        "order_subtotal": properties.orderSubtotal,
        "order_total": properties.orderTotal,
        "order_sales_tax": properties.orderSalesTax,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("D7tRpSNvwf", "Checkout Completed", messages, [
        {id: "2AN5ba0LeC", name: "order_subtotal", value: properties.orderSubtotal},
        {id: "2Cu4pNbef4", name: "order_sales_tax", value: properties.orderSalesTax},
        {id: "BcaLOschrV", name: "item_name", value: properties.itemName},
        {id: "HA4Uq7iqYi", name: "item_qty", value: properties.itemQty},
        {id: "QiAliQO7QY", name: "item_modifier_name", value: properties.itemModifierName},
        {id: "S7Y1CVnA3B", name: "item_unit_price", value: properties.itemUnitPrice},
        {id: "k2b1UHU8jd", name: "order_total", value: properties.orderTotal},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Checkout Completed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Checkout Completed", {
          "item_name": properties.itemName,
          "item_unit_price": properties.itemUnitPrice,
          "item_qty": properties.itemQty,
          "item_modifier_name": properties.itemModifierName,
          "order_subtotal": properties.orderSubtotal,
          "order_total": properties.orderTotal,
          "order_sales_tax": properties.orderSalesTax,
          }, "D7tRpSNvwf", "33f5b61a8f7ce7d8f4e5cd932c4791783f60aa3b32eaa859371fd394424be031");
      }
      // destination SegmentZSFCheckoutV1
      SegmentZSFCheckoutV1.logEvent("Checkout Completed", {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        "order_subtotal": properties.orderSubtotal,
        "order_total": properties.orderTotal,
        "order_sales_tax": properties.orderSalesTax,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Order Confirmation Page Viewed: Sent every time the order confirmation page is viewed. 'Checkout Completed' is sent only once per order. This one may be sent multiple times e.g if user refreshes the page etc
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.itemName - no description
   * @param {float} properties.itemUnitPrice - no description
   * @param {int} properties.itemQty - no description
   * @param {string} properties.itemModifierName - no description
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/IQJ_w0WukQ}
   */
  function orderConfirmationPageViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertItemName(properties.itemName));
      messages = messages.concat(assertItemUnitPrice(properties.itemUnitPrice));
      messages = messages.concat(assertItemQty(properties.itemQty));
      messages = messages.concat(assertOptionalItemModifierName(properties.itemModifierName));
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Order Confirmation Page Viewed", Object.keys(properties), [
        "itemName",
        "itemUnitPrice",
        "itemQty",
        "itemModifierName",
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "IQJ_w0WukQ", "5665d21f7c83680fe6aab97941bf9c9d187eb0fe38de4ea6ac6c664613748473", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Order Confirmation Page Viewed", {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("IQJ_w0WukQ", "Order Confirmation Page Viewed", messages, [
        {id: "BcaLOschrV", name: "item_name", value: properties.itemName},
        {id: "HA4Uq7iqYi", name: "item_qty", value: properties.itemQty},
        {id: "QiAliQO7QY", name: "item_modifier_name", value: properties.itemModifierName},
        {id: "S7Y1CVnA3B", name: "item_unit_price", value: properties.itemUnitPrice},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Order Confirmation Page Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Order Confirmation Page Viewed", {
          "item_name": properties.itemName,
          "item_unit_price": properties.itemUnitPrice,
          "item_qty": properties.itemQty,
          "item_modifier_name": properties.itemModifierName,
          }, "IQJ_w0WukQ", "5665d21f7c83680fe6aab97941bf9c9d187eb0fe38de4ea6ac6c664613748473");
      }
      // destination SegmentZSFCheckoutV1
      SegmentZSFCheckoutV1.page(properties.segmentPageName_, {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        }, {});
      SegmentZSFCheckoutV1.logEvent("Order Confirmation Page Viewed", {
        "item_name": properties.itemName,
        "item_unit_price": properties.itemUnitPrice,
        "item_qty": properties.itemQty,
        "item_modifier_name": properties.itemModifierName,
        }, {});
    } else {
      // do nothing
    }
  }

  exports.setAvoLogger = setAvoLogger;
  exports.itemPageViewed = itemPageViewed;
  exports.itemImageInteraction = itemImageInteraction;
  exports.itemQuantityChanged = itemQuantityChanged;
  exports.itemModifierSelected = itemModifierSelected;
  exports.checkoutInitiated = checkoutInitiated;
  exports.deliveryButtonInteraction = deliveryButtonInteraction;
  exports.checkoutCompleted = checkoutCompleted;
  exports.orderConfirmationPageViewed = orderConfirmationPageViewed;
  exports.setup_ = setup_;
  exports.initAvo = initAvo;
  exports.avoInspectorApiKey = avoInspectorApiKey;
}(typeof exports === 'undefined' ? this.Avo = {} : exports));

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["itemPageViewed","itemImageInteraction","itemQuantityChanged","itemModifierSelected","checkoutInitiated","deliveryButtonInteraction","checkoutCompleted","orderConfirmationPageViewed"]
