import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

export const types = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success'
}

const Message = props => {
  const { type, children, onClose } = props

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <div className={ `zsf-message ${type}` }>
      <div data-testid='message-close' className='close' onClick={ handleClose } />
      { children }
    </div>
  )
}

Message.propTypes = {
  type: PropTypes.string,
  children: PropTypes.any,
  onClose: PropTypes.func
}

export default Message
