import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const Picker = props => {
  const { items, selectedItem, onChange } = props

  const handleItemClick = (option) => {
    if (onChange) {
      onChange(option.item)
    }
  }

  return (
    <div className='zsf-wheelpicker'>
      {
        items.map((item, i) => {
          const isSelected = selectedItem.name === item.item.name
          return (
            <div
              key={ `store-selector-${i}` }
              className={ `picker-item ${isSelected ? 'selected' : ''}` }
              onClick={ () => handleItemClick(item) }
            >
              { item.content() }
            </div>
          )
        })
      }
    </div>
  )
}

Picker.propTypes = {
  items: PropTypes.array,
  selectedItem: PropTypes.object,
  onChange: PropTypes.func
}

export default Picker
