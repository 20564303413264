import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const Pickup = props => {
  const {
    className = '',
    color = '#006B5B'
  } = props
  return (
    <div className={ `eg-pickup ${className}` }>
    <svg className='pickup-icon' width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.66599 0.0829926V1.66599H14.333V0.0829926H1.66599ZM1.27099 3.24999L0.0829926 5.62499V7.20799H0.874993V13.541C0.874993 14.416 1.58299 15.125 2.45799 15.125H13.541C14.416 15.125 15.125 14.416 15.125 13.541V7.20799H15.916V5.62499L14.729 3.24999H1.26999H1.27099ZM2.45799 7.20799H13.541V13.541H11.958V8.79099H8.79099V13.541H2.45799V7.20799ZM4.04099 8.79099V11.958H7.20799V8.79099H4.04099Z" fill={ color } />
    </svg>
      { props.children }
    </div>
  )
}

Pickup.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  className: PropTypes.string
}

export default Pickup
