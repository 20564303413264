import Avo from '../Avo'

export const analyticEvents = {
  PAGE_VIEWED: 'pageViewed',
  HOMEPAGE_VIEWED: 'homePageViewed',
  ITEM_PAGE_VIEWED: 'itemPageViewed',
  ITEM_IMAGE_INTERACTION: 'itemImageInteraction',
  DELIVERY_BUTTON_INTERACTION: 'deliveryButtonInteraction',
  ITEM_MODIFIER_SELECTED: 'itemModifierSelected',
  ITEM_QUALITY_CHANGED: 'itemQuantityChanged',
  CHECKOUT_INITIATED: 'checkoutInitiated',
  CHECKOUT_COMPLETED: 'checkoutCompleted',
  ORDER_CONFIRMATION_PAGE_VIEWED: 'orderConfirmationPageViewed',
  // INTERCOM_CHAT_INITIATED: 'intercomChatInitiated',
  // INTERCOM_CHAT_ABANDONED: 'intercomChatAbandoned',
  CTA_INTERACTION: 'ctaInteraction',
  CONTACT_US_INTERACTION: 'contactUsInteraction',
}

class EGAnalytic {
  constructor() {
    this.Avo = Avo

    if (typeof window !== 'undefined') {
      if (process.env.GATSBY_ENV === 'production') {
        this.Avo.initAvo({ env: 'prod' })
      } else {
        this.Avo.initAvo({ env: 'dev' })
      }
    }
  }

  track(key, opts) {
    try {
      this.Avo[key](opts)
    } catch (e) {
      console.error('Error while tracking event ->', key, opts, e)
    }
  }
}

export const EGAnalytics = new EGAnalytic()
