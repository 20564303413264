import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const BackLink = props => {
  const { children, href, className = '' } = props
  return (
    <a className={ `zsf-back-link ${className}` } href={ href }>
      <div className='back'>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M7.039 0.517989C7.319 0.805989 7.319 1.26499 7.039 1.55299L2.423 6.27999H13C13.398 6.27999 13.72 6.60199 13.72 6.99999C13.72 7.39799 13.398 7.71999 13 7.71999H2.423L7.04 12.447C7.32 12.735 7.32 13.194 7.04 13.482C6.767 13.762 6.32 13.767 6.04 13.494L6.029 13.482L0.205001 7.51799C-0.0679991 7.22599 -0.0679991 6.77399 0.205001 6.48199L6.028 0.517989C6.3 0.237989 6.748 0.232989 7.027 0.505989L7.039 0.517989V0.517989Z" fill="black"/>
        </svg>
      </div>
      <div className='title'>
        { children }
      </div>
    </a>
  )
}

BackLink.propTypes = {
  children: PropTypes.any
}

export default BackLink
