import itemImg from '../assets/fp-item-tomato.jpeg'

const DISCOUNT_PRICES = {
  onMediumPizza: 1.51,
  onLargePizza: 3,
}

export const fpData = (hasDiscountAmount) => {
  const result = {
    itemImg,
    name: 'Original Tomato Pie With Mozz',
    pickupTime: '30 min',
    items: [
      'Mozzarella Cheese, Crushed Italian Tomatoes, Grated Pecorino Romano & Olive Oil'
    ],
  }
  if(hasDiscountAmount) {
    return {
      ...result,
      variants: [
        { name: 'Medium',
          mainPrice: 15.50,
          discountAmount: DISCOUNT_PRICES.onMediumPizza,
          price: 15.50 - DISCOUNT_PRICES.onMediumPizza,
          priceId: process.env.GATSBY_DISCOUNTED_PRICE_ID
        },
        { name: 'Large',
          mainPrice: 19.75,
          discountAmount: DISCOUNT_PRICES.onLargePizza,
          price: 19.75 - DISCOUNT_PRICES.onLargePizza,
          priceId: process.env.GATSBY_DISCOUNTED_LARGE_PRICE_ID }
      ]
    }
  } 
  return {
    ...result,
    variants: [
      { name: 'Medium', price: 15.50, priceId: process.env.GATSBY_MEDIUM_PRICE_ID },
      { name: 'Large', price: 19.75, priceId: process.env.GATSBY_LARGE_PRICE_ID }
    ]
  }
} 

// { name: 'Frank Pepe\'s Burlington', address: '75 Middlesex Turnpike', uuid: 'b32d1d8d-2d07-46d5-a167-ad1d88fbdd3e' },
const storesProd = [
  { name: 'Frank Pepe\'s New Haven', address: '157 Wooster Street', uuid: '27ce9a4c-8f3f-4f3a-99f4-ceb25f493f23' },
  { name: 'Frank Pepe\'s Chestnut Hill', address: '199 Boylston Street', uuid: 'ab7846b3-e7cf-4981-a28d-b58f2e3f3e8c' },
  { name: 'Frank Pepe\'s Danbury', address: '59 Federal Road', uuid: '2bca38a5-d96e-4444-a8e7-d2a317a9ebd2' },
  { name: 'Frank Pepe\'s Fairfield', address: '238 Commerce Drive', uuid: '99d698a5-c78f-4e18-ab16-5ab2949e13bc' },
  { name: 'Frank Pepe\'s Manchester', address: '221 Buckland Hills Drive', uuid: '15da5676-5965-404f-9fad-d3f78768a299' },
  { name: 'Frank Pepe\'s Uncasville', address: '1 Mohegan Sun Boulevard', uuid: 'ddd6d24f-1004-40a0-89c0-52666f8e0453' },
  { name: 'Frank Pepe\'s Warwick', address: '21 Universal Boulevard', uuid: '0daf6428-5bde-4abc-ae96-385ef8d37b18' },
  { name: 'Frank Pepe\'s Waterbury', address: '30 Reidville Drive', uuid: '1cf1f588-6ef2-47c2-847b-a3cfc69d78ab' },
  { name: 'Frank Pepe\'s Watertown', address: 'Eldridge Avenue', uuid: '1b8fd05f-b59b-498a-bcb2-28af02440760' },
  { name: 'Frank Pepe\'s West Hartford', address: '1148 New Britain Avenue', uuid: '3e2159ee-bf83-413e-92d7-8c194d187565' },
  { name: 'Frank Pepe\'s Yonkers', address: '1955 Central Park Avenue', uuid: '99d8a940-684e-4047-9920-54927aa453d6' }
]

const storesDev = [
  { name: 'Casu\'s new store', address: '1000 3rd Street', uuid: 'b782a4e6-ac7b-419d-b1d3-6cb79f46468b' },
  { name: 'HG Test Store 3', address: '1000 3rd St', uuid: '26fa19d8-61cd-40f2-8a05-a7a3f7064b17' },
  { name: 'HG Test Store 6', address: '1000 3rd St', uuid: '38de3817-3c99-46d4-8824-5b5adafe7f57' }
]

export const stores = process.env.GATSBY_ENV === 'production' ? storesProd : storesDev
