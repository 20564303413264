import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const SectionDivision = props => {
  const { text, className = '' } = props

  return (
    <div className={ `zsf-section-division ${className}` }>
      <div className='line'>
        <span className='text'>{ text }</span>
      </div>
    </div>
  )
}

SectionDivision.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
}

export default SectionDivision
