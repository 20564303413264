import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const QuantitySelector = props => {
  const { text, quantity, onChange } = props
  const shouldDisableDecrement = quantity <= 1

  const increment = () => {
    if (onChange) {
      onChange(quantity + 1)
    }
  }

  const decrement = () => {
    if (!shouldDisableDecrement && onChange) {
      onChange(quantity - 1)
    }
  }

  const handleQtyChange = (e) => {
    const value = e.target.value
    if (value.match(/^[0-9]+$/) && value !== '0') {
      let quantity = e.target.value !== '' ? parseInt(e.target.value) : ''
      if(onChange) {
        onChange(quantity)
      }
    }
  }

  return (
    <div className='zsf-quantity-selector'>
      <div
        data-testid='quantity-selector-minus'
        className='action'
        onClick={ decrement }
      >
        <div className={ `decrement ${ shouldDisableDecrement ? 'disabled' : ''}` }></div>
      </div>
      <div className='text'>
        <input
          type="text"
          value={ quantity }
          className='input'
          onChange={ handleQtyChange }
        />
        { text }
      </div>
      <div
        data-testid='quantity-selector-plus'
        className='action'
        onClick={ increment }
      >
        <div className='increment'></div>
      </div>
    </div>
  )
}

QuantitySelector.propTypes = {
  quantity: PropTypes.number.isRequired,
  text: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default QuantitySelector
