import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

/**
 * RadioButtons React component. Renders a custom radio button input given the options.
 * 
 * Properties
 * - name {string}          - Will group the radio inputs together [Required]
 * - options {array}        - An array of options to be selected composed by `value` & `label` [Required]
 * - defaultValue {string}  - The default value to select.
 * - onChange {func}        - Function triggered on the value change.
 */
const RadioButtonGroup = (props) =>  {
  const [ value, setValue ] = useState(props.defaultValue)
  const {
    className= '',
    labelClassName= '',
    optionClassName= ''
  } = props

  /**
   * Sets the value to the state and calls the onChange prop if provided.
   * @param {string} value 
   */
  const onChange = (event, selectedValue, selectedOption)  => {
    event && event.preventDefault()
    setValue(selectedValue)
    if (props.onChange) props.onChange(selectedValue, selectedOption)
  }

  return (
    <div className={`container ${className}`} >
      {props.options.map( option => (
        <div
          className={`optionContainer ${optionClassName}`}
          key={option.value}
          onClick={ (event) => onChange(event, option.value, option)}
        >
          <input
            id={option.name}
            name={props.name}
            type="radio"
            value={option.value}
            checked={value === option.value}
            onChange={ (event) => onChange(event, option.value, option) }
          />
          <div className='checkmark'><div/></div>
          <label htmlFor={ option.name } className={`label ${labelClassName}`}>{option.label}</label>
        </div>
      ))}
    </div>
  )
}

RadioButtonGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  optionClassName: PropTypes.string
}

export default RadioButtonGroup
