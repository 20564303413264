import React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import { toCurrency } from '../../../utils'
import Loader from '../../Loader'
// import img from '../../../assets/fp-item-tomato.jpeg'

import './styles.scss'

const ProductSummary = props => {
  const {
    hasDiscountAmount = false,
    variant,
    selectedQuantity,
    description,
    paymentData,
    storeLoading
  } = props

  const { subtotal, total, tax } = paymentData

  const subTotalToShow = hasDiscountAmount ? toCurrency(selectedQuantity * variant.mainPrice): toCurrency(subtotal)
  return (
    <div className='zsf-checkout-product-summary'>
      <div className='item-details' >
        <StaticImage
          src='../../../assets/fp-item-tomato.jpeg' // TODO: take this from data object
          className='item-img'
          alt='item'
          width={ 64 }
          height={ 64 }
        />
        <div className='item-info'>
          <div className='description'>{ description }</div>
          <div className='price'>{ `$${subTotalToShow}` }</div>
        </div>
      </div>
      {
        !storeLoading ? (
          <div className='totals'>
            <div className='row'>
              <div>Subtotal</div>
              <div>{ `$${subTotalToShow}` }</div>
            </div>
            {hasDiscountAmount && (
              <div className='row discount-color'>
                <div>Discount</div>
                <div>{ `- $${toCurrency(selectedQuantity * variant.discountAmount)}` }</div>
              </div>
            )}
            <div className='row'>
              <div>Tax</div>
              <div>{ `$${toCurrency(tax)}` }</div>
            </div>
            <div className='row total'>
              <div>Total</div>
              <div>{ `$${toCurrency(total)}` }</div>
            </div>
          </div>
        ) : (
          <div className='loading'>
            <Loader className='loader' />
          </div>
        )
      }
    </div>
  )
}

ProductSummary.propTypes = {
  hasDiscountAmount: PropTypes.bool,
  variant: PropTypes.object.isRequired,
  selectedQuantity: PropTypes.number.isRequired,
  image: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  storeLoading: PropTypes.bool
}

export default ProductSummary
