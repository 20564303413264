const methodsWithBody = [ 'POST', 'PUT', 'PATCH' ]

export const apiCall = async (url, opts) => {
  const { method, data } = opts
  const fetchParams = {
    method,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  if (methodsWithBody.includes(method)) {
    fetchParams.body = JSON.stringify(data)
  }
  const res = await fetch(url, fetchParams)
  if (!res.ok) {
    const body = await res.json()
    if (body.message) {
      throw new Error(body.message)
    } else {
      throw new Error('Something went wrong, please try again later')
    }
  }
  return await res.json()
}
