import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import BackLink from '../BackLink'
import ProductSummary from './ProductSummary'
import CheckoutForm from './CheckoutForm'
import { fpData as getPriceData } from '../../data'
import './styles.scss'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_API_KEY)

// we have to pass the font source, so Stripe can include it properly, since
// the fields are embeded in iframes, they have no direct access to the app fonts
const fonts = [{ cssSrc: "https://fonts.googleapis.com/css?family=Lato:400" }]

const Checkout = props => {
  const [ storeLoading, setStoreLoading ] = useState(false)
  const {
    hasDiscountAmount,
    paymentData,
    variant,
    quantity,
    store,
    onStoreChange,
    onPaymentComplete
  } = props
  const data = getPriceData(hasDiscountAmount)

  const handlePaymentComplete = () => {
    onPaymentComplete()
  }

  const handleStoreChange = (store, totals) => {
    if (onStoreChange) {
      onStoreChange(store, totals)
    }
  }

  const handleStoreLoading = () => {
    setStoreLoading(true)
  }

  const handleStoreLoadingComplete = () => {
    setStoreLoading(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='zsf-checkout'>
      <BackLink href={hasDiscountAmount ? '/offer' : '/'}>{ store.name }</BackLink>
      <ProductSummary
        hasDiscountAmount={hasDiscountAmount}
        image={ data.itemImg }
        description={ `${quantity}x ${data.name} - ${variant.name}` }
        selectedQuantity={quantity}
        variant={variant}
        paymentData={ paymentData }
        storeLoading={ storeLoading }
      />
      <Elements stripe={ stripePromise } options={ { fonts } }>
        <CheckoutForm
          paymentData={ paymentData }
          store={ store }
          variant={ variant }
          selectedQuantity={quantity}
          onStoreChange={ handleStoreChange }
          onPaymentComplete={ handlePaymentComplete }
          onStoreLoading={ handleStoreLoading }
          onStoreLoadingComplete={ handleStoreLoadingComplete }
        />
      </Elements>
    </div>
  )
}

Checkout.propTypes = {
  clientSecret: PropTypes.string,
  variant: PropTypes.object,
  quantity: PropTypes.number,
  store: PropTypes.object.isRequired,
  onStoreChange: PropTypes.func,
  onPaymentComplete: PropTypes.func.isRequired
}

export default Checkout
